.seek{
    width: 100%;
    height: 8px;
    border-radius: 10px;
    background-color: #b3d4bb;
    margin-bottom: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)
}

.responseCard{
    padding: 15px 10px;
    border: 2px solid #96b59e;
    transition: 1s;
    background-color: #fff;
    margin: 15px auto;
    border-radius: 10px;
    cursor: pointer;
}

.responseCard:hover, .active-card{
    transition: 1s;
    background-color: #96b59e;
}

.responseCard:hover h4, .active-card h4{
    color: #fff;
}

.btn-back{
    transition: 1s;
    border-bottom: 2px solid #3a6e47;
    width: 57px;
    cursor: pointer;
}

.btn-back:hover{
    border-bottom: 2px solid #fb4b74;
}

.btn-back h4{
    margin: 0;
}
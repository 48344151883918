.footer-topContainer {
  width: 100%;
  padding: 50px 0;
  background-color: #6d6d6d;
}

.footer-bottomContainer {
  width: 100%;
  padding: 15px 0;
  background-color: #96b59e;
}

.footer-icon {
  transition: 1s;
  color: #fff;
  margin-bottom: 0.5rem;
}

.footer-icon2 {
  transition: 1s;
  color: #6d6d6d;
  margin-bottom: 0.5rem;
}

.footer-topContainer a:hover,
.footer-icon:hover,
.footer-icon2:hover {
  transition: 1s;
  color: #96b59e;
}

.footer-right-div {
  text-align: right;
}

.footer-end-div {
  justify-content: flex-end;
}

@media (max-width: 767.5px) {
  .footer-right-div {
    margin-top: 20px;
    text-align: center;
  }

  .footer-end-div {
    justify-content: center;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  /* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

code {
  font-family: 'Quicksand', sans-serif;
}

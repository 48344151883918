.contact-container {
  width: 100%;
  padding: 120px 0 80px;
  background-color: #96b59e;
  border-bottom-right-radius: 92%;
}

.form-wrapper {
  text-align: left;
  width: 50%;
  transition: 1s;
  border-radius: 25px;
  padding: 25px;
  background-color: #fff;
  margin: auto;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.form-wrapper-contact {
  text-align: left;
  width: 100%;
  transition: 1s;
  padding: 25px;
  border-right: 2px solid #96b59e;
}

.contact-wrapper {
  background-color: #fff;
  border-radius: 25px;
  width: 100%;
  display: grid;
  transition: 1s;
  grid-template-columns: 1fr 1fr;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 1280px) {
  .form-wrapper {
    width: 90%;
  }

  .contact-wrapper {
    width: 90%;
  }
}

@media (max-width: 767.5px) {
  .contact-wrapper {
    grid-template-columns: 1fr;
  }

  .form-wrapper-contact {
    border-right: none;
  }

  .address-wrapper{
      padding: 40px 0;
      border-top: 2px solid #96b59e;
  }
}

.navbar {
  background-color: #96B59E;
  transition: 1s;
  top: 0;
  width: 100%;
  z-index: 100;
}

.nav-link {
  color: #fff;
  transition: 1s;
  margin: auto auto auto 1.2rem;
}

.navbar-brand {
  margin: 0;
  color: #fff;
  position: absolute;
  top: 0;
}

.nav-link:hover,
.active {
  color: #3a6e47;
}

.call-to-action, input[type=file]::-webkit-file-upload-button{
    background-color: #fb4b74;
    transition: 2s;
    border-radius: 10px;
    border: none;
}

input[type=file]::-webkit-file-upload-button{
  padding: 5px 10px;
  margin: 5px 10px 0 0px;
  color: #fff;
  cursor: pointer;
}

.call-to-action:hover{
    color: #fff;
    background-color: #6d6d6d;
}

@media (max-width: 991px) {
  .navbar {
    text-align: center !important;
  }

  .nav-link {
    margin: 0;
  }
}

.main-container {
  background-color: #96b59e;
  transition: 1s;
  width: 100%;
  margin: 0;
  padding: 0 0 80px;
  border-bottom-left-radius: 50%;
}

.desc-wrapper {
  padding: 40px 40px 120px;
}

#img-tutoring {
  width: 90%;
  transition: 1s;
}

#pc-icon {
  width: 70px;
  padding: 10px;
  height: 70px;
  border-radius: 25px;
  background-color: #96b59e;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #fff;
}

#people-icon {
  width: 70px;
  padding: 10px;
  height: 70px;
  border-radius: 25px;
  background-color: #fb4b74;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.numbering {
  width: 55px;
  height: 55px;
  border-radius: 30px;
}

@media (max-width: 1050px) {
  .main-container {
    padding: 40px 0 80px;
  }
}

@media (max-width: 991px) {
  #img-tutoring {
    width: 100%;
  }

  .main-container {
    padding: 40px 0 40px;
  }
}

@media (max-width: 767.5px) {
  .services-wrapper {
    margin-top: 40px;
  }

  .main-container {
    padding: 40px 0 0px;
  }
}

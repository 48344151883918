.App,
html,
body {
  font-weight: 500;
  transition: 1s;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(256, 256, 256)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

a {
  text-decoration: none;
}

.App {
  max-width: 1280px;
  margin: auto;
  padding: 0px 40px;
  width: 100%;
}

.row {
  margin: 0;
  width: 100%;
}

.vCentered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  color: #fff;
  transition: 1s;
}

.link-black {
  color: #6d6d6d;
  transition: 1s;
}

.link:hover, .link-black:hover {
  color: #3a6e47;
}

.gText {
  color: #3a6e47;
}

.lgText {
  color: #96b59e;
}

.rText {
  color: #fb4b74;
}

.bText {
  color: #000;
}

.greyText {
  color: #6d6d6d;
}

.wText {
  color: #fff;
}

h1 {
  font-size: 3rem;
  font-weight: bold;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
}

h4 {
  font-size: 1rem;
}

h5 {
  font-size: 0.9rem;
}

.flexDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator {
  margin: 30px auto;
  border-top: 2px solid #fb4b74;
  border-bottom: 2px solid #fb4b74;
  width: 20%;
  height: 4px;
}

input[type="text"],
textarea,
input[type="phone"] {
  height: 42px;
  margin: 10px auto 20px;
  width: 100%;
  border-radius: 10px;
  border: 2px solid #96b59e;
  padding-left: 15px;
}

textarea {
  min-height: 250px;
}

/* Styling the Scroll bar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6d6d6d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 991px) {
  h1 {
    font-size: 2.6rem;
  }

  h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 767.5px) {
  .App,
  html,
  body {
    text-align: center !important;
  }
}
